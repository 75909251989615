import React from 'react'
import { FiltersType } from '@/types/filters'
import { Form, Formik } from 'formik'
import { Show } from '@/components/conditions/Show'
import { FUNCTIONS_CODES, USER_ROLES } from '@/constants/common'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { Option } from '@/types/common'
import { filtersSlice } from '@/storage/filters'
import { FilterField } from '@/components/header/FilterField'
import { getCurrentFYOptions, getFyItems } from '@/utils/dates'
import { MANAGER_STATUSES_OPTIONS, STATUSES_OPTIONS } from '@/constants/statuses'

export const Filters = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  const { countries, functionCodes, filters, sites } = useSelector((state: RootState) => ({
    ...state.sites,
    filters: state.filters.filters
  }))

  const currentFYOptions = getCurrentFYOptions()
  const yearsOptions = getFyItems()

  const handleSave = (values: { [key in string]: Option<number | string> }) => {
    store.dispatch(filtersSlice.actions.setFilter(values))
  }

  const siteList: Option[] = React.useMemo(() => {
    return sites
      .filter(item => !user?.is_centres || item.function_code === FUNCTIONS_CODES.centers)
      .filter(item => user?.country?.some(code => code === item.country_code))
      .map(item => ({
        label: item.site_name,
        value: item.site_name,
        id: item.site_name
      }))
  }, [countries, user?.is_centres])

  const filteredCountries = countries.filter(item => user?.country?.some(code => code === item.value)) || []
  const filteredCodes = functionCodes.filter(item => item.id !== FUNCTIONS_CODES.centers)
  const countryManagerFilters = (
    <>
      <FilterField name="fy" label="header.filters.year" withLabel withoutAll list={yearsOptions} />
      <FilterField name="report_period" label="header.filters.reporting_period" withLabel list={currentFYOptions} />
      <FilterField name="status" label="header.filters.status" withLabel list={MANAGER_STATUSES_OPTIONS} />
      <Show when={Number(user?.country?.length) > 1 && !user?.is_centres}>
        <FilterField name="country_code" label="header.filters.country_code" withLabel list={filteredCountries} />
      </Show>
      <Show when={Boolean(user?.is_centres)}>
        <FilterField name="country_code" label="header.filters.country_code" withLabel list={countries} />
      </Show>
      <FilterField name="site" label="header.filters.site" withLabel list={siteList} />
      <Show when={!user?.is_centres}>
        <FilterField name="function_code" label="header.filters.function_code" list={filteredCodes} />
      </Show>
    </>
  )

  const globalManagerFilters = (
    <>
      <FilterField name="fy" label="header.filters.year" withLabel withoutAll list={yearsOptions} />
      <FilterField name="report_period" label="header.filters.reporting_period" withLabel list={currentFYOptions} />
      <FilterField name="status" label="header.filters.status" withLabel list={MANAGER_STATUSES_OPTIONS} />
      <FilterField name="country_code" label="header.filters.country_code" withLabel list={countries} />
      <Show when={!user?.is_centres}>
        <FilterField name="function_code" label="header.filters.function_code" list={functionCodes} />
      </Show>
    </>
  )

  const userFilters = (
    <>
      <FilterField name="fy" label="header.filters.year" withLabel withoutAll list={yearsOptions} />
      <FilterField name="report_period" label="header.filters.reporting_period" withLabel list={currentFYOptions} />
      <FilterField name="status" label="header.filters.status" withLabel list={STATUSES_OPTIONS} />
      <FilterField name="country_code" label="header.filters.country_code" withLabel list={countries} />
      <FilterField name="function_code" label="header.filters.function_code" list={functionCodes} />
    </>
  )

  const dataReportingFilters = (
    <>
      <FilterField name="fy" label="header.filters.year" withLabel withoutAll list={yearsOptions} />
      <FilterField name="report_period" label="header.filters.reporting_period" withLabel list={currentFYOptions} />
      <FilterField name="status" label="header.filters.status" withLabel list={[STATUSES_OPTIONS[4]]} />
      <FilterField name="country_code" label="header.filters.country_code" withLabel list={countries} />
      <FilterField name="function_code" label="header.filters.function_code" list={functionCodes} />
    </>
  )

  return (
    <Formik<FiltersType> initialValues={filters} enableReinitialize onSubmit={handleSave}>
      <Form>
        <Show when={location.pathname !== '/data-reporting'}>
          <Show when={user?.role === USER_ROLES.user}>{userFilters}</Show>
          <Show when={user?.role === USER_ROLES.countryManager}>{countryManagerFilters}</Show>
          <Show when={user?.role === USER_ROLES.globalManager}>{globalManagerFilters}</Show>
        </Show>
        <Show when={location.pathname === '/data-reporting'}>{dataReportingFilters}</Show>
      </Form>
    </Formik>
  )
}
