import React, { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { LANGUAGES_LIST } from '@/constants/common'
import { LanguagesType } from '@/types/translations'
import DOMPurify from 'dompurify'

import en from '@/translations/en.json'
import de from '@/translations/de.json'
import fr from '@/translations/fr.json'

const data: { [key in LanguagesType]: { [key in string]: string } } = { en, de, fr }

type Props = {
  id: string
  withTags?: boolean
  className?: string
  style?: CSSProperties | undefined
  params?: { [key in string]: string }
}

const replaceUrl = (translationString: string, params: { [key in string]: string }) => {
  // Create regex using the keys of the replacement object.
  const regex = new RegExp('{{(' + Object.keys(params).join('|') + ')}}', 'g')

  // Replace the string by the value in object
  return translationString.replace(regex, (m, $1) => params[$1] || m)
}

// Assign true to this and with that you can check if something is not added to translations
const debug = false

export const DynamicTranslations: React.FC<Props> = props => {
  const { id, withTags, className, style, params } = props
  const language = useSelector((store: RootState) => store.locale.language)

  if (debug) return <>{id}</>

  const defaultTranslation = data[LANGUAGES_LIST[0]][id]

  if (!defaultTranslation) return <>{id}</>

  const translated = (data[language] || {})[id] || defaultTranslation
  const translatedWithValues = params ? replaceUrl(translated, params) : translated

  if (withTags)
    return (
      <div
        className={className}
        style={style}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translatedWithValues) }}
      />
    )

  return <>{translatedWithValues}</>
}

export const StaticTranslation = (id: string, params?: { [key in string]: string }): string => {
  const language = store.getState().locale.language

  const defaultTranslation = data[LANGUAGES_LIST[0]][id]

  if (!defaultTranslation) return id

  const translated = (data[language] || {})[id] || defaultTranslation
  return params ? replaceUrl(translated, params) : translated
}

export const useTranslation = (id: string) => {
  const language = useSelector((store: RootState) => store.locale.language)

  if (debug) return id

  const defaultTranslation = data[LANGUAGES_LIST[0]][id]

  if (!defaultTranslation) return id

  return (data[language] || {})[id] || defaultTranslation
}
