import React from 'react'
import Text from '@ingka/text'
import EditIcon from '@ingka/ssr-icon/paths/pencil'
import DeleteIcon from '@ingka/ssr-icon/paths/trash-can'
import clx from 'classnames'
import Tooltip from '@ingka/tooltip'

import { StatusTag } from '@/pages/Initiatives/components/Status'
import styles from '../../styles/Initiatives.module.scss'
import Button from '@ingka/button'
import { useNavigate } from 'react-router-dom'
import { TabsInfo } from '@/pages/Initiatives/components/Details/TabsInfo'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { Show } from '@/components/conditions/Show'
import { STATUSES } from '@/constants/statuses'
import { submitForReview } from '@/pages/Initiatives/utils/submitForReview'
import { handleToggleDeleteConfirmation } from '@/pages/Initiatives/utils/remove'
import { DynamicTranslations } from '@/components/translations/Translations'

export const InitiativeDetails = () => {
  const { details } = useSelector((state: RootState) => state.initiatives)
  const navigate = useNavigate()
  const [showDesc, setShowDesc] = React.useState(false)
  const [toggleAvailable, setShowToggle] = React.useState(false)
  const descRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setShowToggle(Number(descRef.current?.clientHeight) >= 88)
  }, [details])

  if (!details) return null

  const { status, title, id, description } = details

  const handleToggleDesc = () => {
    setShowDesc(!showDesc)
  }

  return (
    <div className={styles.InitiativeDetails} id="initiative-details">
      <div className={styles.Head}>
        <div className={styles.TitleSection}>
          <StatusTag status={status} />
          <Text headingSize="l">{title}</Text>
        </div>

        <div className={styles.Controls}>
          <Show when={details.status === STATUSES[3]}>
            <Button type="emphasised" small onClick={submitForReview(details, navigate)}>
              <DynamicTranslations id="initiatives.details.submit" />
            </Button>
          </Show>
          <Show
            when={details.status === STATUSES[0] || details.status === STATUSES[1] || details.status === STATUSES[3]}
          >
            <Tooltip boundaryContainerID="initiative-details" tooltipText="Edit initiative" position="bottom">
              <Button
                data-testid="initiatives-edit"
                small
                ssrIcon={EditIcon}
                iconOnly
                onClick={() => navigate(`/edit/${id}`)}
              />
            </Tooltip>
          </Show>
          <Show when={details.status === STATUSES[0] || details.status === STATUSES[3]}>
            <Tooltip boundaryContainerID="initiative-details" tooltipText="Delete initiative" position="bottom">
              <Button
                data-testid="initiatives-delete"
                className={styles.DeleteButton}
                small
                ssrIcon={DeleteIcon}
                iconOnly
                onClick={handleToggleDeleteConfirmation(id)}
              />
            </Tooltip>
          </Show>
        </div>
      </div>
      <div className={styles.ScrollBlock}>
        <div ref={descRef} className={clx(styles.DescriptionBlock, showDesc ? styles.Show : styles.Hide)}>
          <Text bodySize="m">{description}</Text>
        </div>
        <Show when={toggleAvailable}>
          <Text bodySize="m" className={styles.Toggle} onClick={handleToggleDesc}>
            {showDesc ? 'Hide' : 'Show'}
          </Text>
        </Show>
        <TabsInfo />
      </div>
    </div>
  )
}
