import React from 'react'
import Text from '@ingka/text'
import CrossSmall from '@ingka/ssr-icon/paths/cross-small'
import CheckSmall from '@ingka/ssr-icon/paths/checkmark-small'

import { StatusTag } from '@/pages/Initiatives/components/Status'
import styles from '../../styles/Initiatives.module.scss'
import Button from '@ingka/button'
import { TabsInfo } from '@/pages/Initiatives/components/Details/TabsInfo'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { handleApproveCountryInitiative } from '@/pages/Initiatives/utils/review'
import clx from 'classnames'
import { Show } from '@/components/conditions/Show'
import { EDIT_WITH_STATUSES, STATUSES } from '@/constants/statuses'
import { submitForReview } from '@/pages/Initiatives/utils/submitForReview'
import Tooltip from '@ingka/tooltip'
import EditIcon from '@ingka/ssr-icon/paths/pencil'
import ChatIcon from '@ingka/ssr-icon/paths/chat'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@ingka/ssr-icon/paths/trash-can'
import { handleToggleDeleteConfirmation } from '@/pages/Initiatives/utils/remove'
import { handleToggleLeaveComment } from '@/pages/Initiatives/utils/comment'
import { More } from '@/pages/Initiatives/components/Details/More'
import { DynamicTranslations } from '@/components/translations/Translations'

export const CountryDetails: React.FC = () => {
  const { details } = useSelector((state: RootState) => state.initiatives)
  const [showDesc, setShowDesc] = React.useState(false)
  const [toggleAvailable, setShowToggle] = React.useState(false)
  const descRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setShowToggle(Number(descRef.current?.clientHeight) >= 88)
  }, [details])

  if (!details) return null

  const { status, title, description } = details

  const handleToggleDesc = () => {
    setShowDesc(!showDesc)
  }

  return (
    <div className={styles.InitiativeDetails}>
      <div className={styles.Head}>
        <div className={styles.TitleSection}>
          <StatusTag status={status} />
          <Text headingSize="l">{title}</Text>
        </div>

        <div className={styles.Controls}>
          <Controls />
        </div>
      </div>
      <div className={styles.ScrollBlock}>
        <div ref={descRef} className={clx(styles.DescriptionBlock, showDesc ? styles.Show : styles.Hide)}>
          <Text bodySize="m">{description}</Text>
        </div>
        <Show when={toggleAvailable}>
          <Text bodySize="m" className={styles.Toggle} onClick={handleToggleDesc}>
            {showDesc ? 'Hide' : 'Show'}
          </Text>
        </Show>
        <TabsInfo />
      </div>
    </div>
  )
}

const Controls: React.FC = () => {
  const userId = useSelector((state: RootState) => state.auth.user?.id)
  const centresOnly = useSelector((state: RootState) => state.auth.user?.is_centres)
  const { details } = useSelector((state: RootState) => state.initiatives)

  const navigate = useNavigate()

  if (!details) return null

  const handleReject = () => store.dispatch(initiativesSlice.actions.setToReject(details.id))
  const statusToApprove = centresOnly ? STATUSES[4] : STATUSES[2]

  const handleApprove =
    !centresOnly && userId === details?.user_id && details.rejected_by_global
      ? handleToggleLeaveComment(details.id, true)
      : handleApproveCountryInitiative(details.id, statusToApprove)

  const showMore = centresOnly
    ? details.status === STATUSES[4] || details.status === STATUSES[2]
    : details.status === STATUSES[2]

  if (details.rejected_by_global && details.status === STATUSES[1]) {
    return (
      <>
        <Show when={userId !== details.user_id}>
          <Button small ssrIcon={CrossSmall} onClick={handleReject}>
            <DynamicTranslations id="initiatives.details.reject" />
          </Button>
        </Show>
        <Button type="emphasised" small ssrIcon={EditIcon} onClick={() => navigate(`/edit/${details.id}`)}>
          <DynamicTranslations id="initiatives.details.edit_approve" />
        </Button>
        <Tooltip boundaryContainerID="initiative-details" tooltipText="Leave comment" position="bottom">
          <Button small ssrIcon={ChatIcon} iconOnly onClick={handleToggleLeaveComment(details.id, false)} />
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <Show when={details.status === STATUSES[1]}>
        <Show when={userId !== details.user_id}>
          <Button small ssrIcon={CrossSmall} onClick={handleReject}>
            <DynamicTranslations id="initiatives.details.reject" />
          </Button>
        </Show>
        <Button type="emphasised" small ssrIcon={CheckSmall} onClick={handleApprove}>
          <DynamicTranslations id="initiatives.details.approve" />
        </Button>
      </Show>
      {/* Just for positioning ---- */}
      <Show when={userId !== details.user_id && EDIT_WITH_STATUSES.some(item => item === details?.status)}>
        <Tooltip boundaryContainerID="initiative-details" tooltipText="Edit initiative" position="bottom">
          <Button small ssrIcon={EditIcon} iconOnly onClick={() => navigate(`/edit/${details.id}`)} />
        </Tooltip>
      </Show>
      {/* ---- */}
      <Show when={userId === details.user_id}>
        <Show when={details.status === STATUSES[3]}>
          <Button type="emphasised" small onClick={submitForReview(details, navigate)} data-testid="initiatives-submit">
            <DynamicTranslations id="initiatives.details.submit" />
          </Button>
        </Show>
        <Show when={EDIT_WITH_STATUSES.some(item => item === details?.status)}>
          <Tooltip boundaryContainerID="initiative-details" tooltipText="Leave comment" position="bottom">
            <Button
              data-testid="initiatives-comment"
              small
              ssrIcon={ChatIcon}
              iconOnly
              onClick={handleToggleLeaveComment(details.id, false)}
            />
          </Tooltip>
          <Tooltip boundaryContainerID="initiative-details" tooltipText="Edit initiative" position="bottom">
            <Button
              data-testid="initiatives-edit"
              small
              ssrIcon={EditIcon}
              iconOnly
              onClick={() => navigate(`/edit/${details.id}`)}
            />
          </Tooltip>
        </Show>
        <Show when={details.status === STATUSES[0] || details.status === STATUSES[3]}>
          <Tooltip boundaryContainerID="initiative-details" tooltipText="Delete initiative" position="bottom">
            <Button
              data-testid="initiatives-delete"
              className={styles.DeleteButton}
              small
              ssrIcon={DeleteIcon}
              iconOnly
              onClick={handleToggleDeleteConfirmation(details.id)}
            />
          </Tooltip>
        </Show>
      </Show>
      <Show when={showMore}>
        <More />
      </Show>
    </>
  )
}
