import React from 'react'
import { DynamicTranslations } from '@/components/translations/Translations'
import styles from '../../styles/Validation.module.scss'

const ValidationMessageImpl = (id: string, withTags = false) => (
  <DynamicTranslations className={styles.Validation} id={id} withTags={withTags} />
)

export const ValidationMessage =
  (id: string, withTags = false) =>
  () =>
    ValidationMessageImpl(id, withTags)
