import Button from '@ingka/button'
import React from 'react'
import Text from '@ingka/text'
import styles from './Login.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'
import { LanguageSelector } from '@/components/header/LanguageSelector'

type Props = {
  onClick: () => void
}

export const Login: React.FC<Props> = props => {
  return (
    <div className={styles.LoginWrap}>
      <div className={styles.LoginBox}>
        <Text headingSize="l" tagName="h1">
          <DynamicTranslations id="login.title" />
        </Text>
        <Text bodySize="xl">
          <DynamicTranslations id="login.description" />
        </Text>
        <LanguageSelector className={styles.LoginAction} small={false} />
        <Button type="primary" className={styles.LoginAction} onClick={props.onClick}>
          <DynamicTranslations id="login.sign_in" />
        </Button>
      </div>
    </div>
  )
}
