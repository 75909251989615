import React from 'react'
import Button from '@ingka/button'
import MoreIcon from '@ingka/ssr-icon/paths/ellipses-horizontal'
import { Show } from '@/components/conditions/Show'
import { List } from '@/components/form/dropdown/List'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import styles from '../../styles/Initiatives.module.scss'
import { handleToggleRecallConfirmation } from '@/pages/Initiatives/utils/review'
import { useOnClickOutside } from '@/components/hooks/outside'

const options = [
  {
    id: 'recall',
    value: 'recall',
    label: 'recall.item_option'
  }
]

export const More = () => {
  const details = useSelector((state: RootState) => state.initiatives.details)
  const [moreOpen, setMoreOpen] = React.useState(false)

  const ref = React.useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    setMoreOpen(false)
  })

  if (!details) return null

  return (
    <div className={styles.More} ref={ref}>
      <Button data-testid="initiatives-more" small ssrIcon={MoreIcon} iconOnly onClick={() => setMoreOpen(true)} />
      <Show when={moreOpen}>
        <List
          className={styles.MoreDropdown}
          options={options}
          onClick={option => () => {
            if (option.value === options[0].value) {
              handleToggleRecallConfirmation(details.id)()
            }

            setMoreOpen(false)
          }}
        />
      </Show>
    </div>
  )
}
