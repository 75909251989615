import * as Yup from 'yup'

import { FIELD_NAME } from '../constants/types'
import { LEVEL_ITEMS, MAIN_MOVEMENT_OPTIONS, MOVEMENT_OPTIONS, TYPE_OF_INITIATIVE_OPTIONS } from '../constants/options'
import { Option } from '@/types/common'
import { isNil } from 'lodash'
import { ValidationMessage } from '../components/Fields/Validation'

const REQUIRED = ValidationMessage('form.validation.required')
const NUMBER = ValidationMessage('form.validation.number')
const INTEGER = ValidationMessage('form.validation.integer')
const MAX_SYMBOLS = ValidationMessage('form.validation.max_symbols')
const MAX_SYMBOLS_TEXT_AREA = ValidationMessage('form.validation.max_symbols_text_area')
const MIN_SYMBOLS_DESCRIPTION = ValidationMessage('form.validation.min_symbols_description')

const COWORKER_ENGAGEMENT_ERROR = ValidationMessage('form.validation.coworkers_engagement_error')
const CUSTOMER_ENGAGEMENT_ERROR = ValidationMessage('form.validation.customer_engagement_error')
const AT_LEAST_15_IN_KIND = ValidationMessage('form.validation.at_least_15_in_kind')
const AT_LEAST_50_IN_KIND = ValidationMessage('form.validation.at_least_50_in_kind')
const PARTICIPANTS_OF_SKILLS_DEVELOPMENT = ValidationMessage('form.validation.participants_of_skills_development')
const CUSTOM_LEVEL = ValidationMessage('form.validation.custom_level')
const MOVEMENT_CUSTOM_CC = ValidationMessage('form.validation.movement_custom_cc')
const MOVEMENT_CUSTOM_UPSKILLING = ValidationMessage('form.validation.movement_when_to_3', true)

export const INPUT_SYMBOLS_MAX = 400
export const TEXT_AREA_SYMBOLS_MAX = 2500
export const DESCRIPTION_MIN_SYMBOLS = 140

export const NewReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA)
    .required(REQUIRED),
  [FIELD_NAME.report_period]: Yup.string().required(REQUIRED),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.people_impacted]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.people_supported_count]: Yup.number().integer(INTEGER).typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.main_movement_supported]: Yup.object()
    .required(REQUIRED)
    .test('movement_custom_coworker_customer', MOVEMENT_CUSTOM_CC, (value, context) => {
      return !(
        (value as Option)?.id !== MAIN_MOVEMENT_OPTIONS[5].id &&
        !(context.parent[FIELD_NAME.movement_supported] || []).some(
          (item: Option) => item.id === MOVEMENT_OPTIONS[5].id
        ) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[5].id || item.id === TYPE_OF_INITIATIVE_OPTIONS[6].id
        )
      )
    })
    .test('movement_custom_upskilling', MOVEMENT_CUSTOM_UPSKILLING, (value, context) => {
      return !(
        (value as Option)?.id !== MAIN_MOVEMENT_OPTIONS[2].id &&
        !(context.parent[FIELD_NAME.movement_supported] || []).some(
          (item: Option) => item.id === MOVEMENT_OPTIONS[2].id
        ) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) =>
            item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[3].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[4].id
        )
      )
    }),
  [FIELD_NAME.movement_supported]: Yup.array()
    .test('movement_custom_coworker_customer', MOVEMENT_CUSTOM_CC, (value, context) => {
      return !(
        context.parent[FIELD_NAME.main_movement_supported]?.id !== MAIN_MOVEMENT_OPTIONS[5].id &&
        !(value || []).some((item: Option) => item.id === MOVEMENT_OPTIONS[5].id) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[5].id || item.id === TYPE_OF_INITIATIVE_OPTIONS[6].id
        )
      )
    })
    .test('movement_custom_upskilling', MOVEMENT_CUSTOM_UPSKILLING, (value, context) => {
      return !(
        context.parent[FIELD_NAME.main_movement_supported]?.id !== MAIN_MOVEMENT_OPTIONS[2].id &&
        !(value || []).some((item: Option) => item.id === MOVEMENT_OPTIONS[2].id) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) =>
            item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[3].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[4].id
        )
      )
    }),
  [FIELD_NAME.type_of_initiative]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.participants_of_skills_development]: Yup.number()
    .typeError(NUMBER)
    .test('less-than-people_supported_count', PARTICIPANTS_OF_SKILLS_DEVELOPMENT, (value, context) => {
      return !(
        (Number(value) > context.parent[FIELD_NAME.people_supported_count] || isNil(value)) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id
        )
      )
    }),
  [FIELD_NAME.spent]: Yup.number()
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('more-than-15', AT_LEAST_15_IN_KIND, (value, context) => {
      return !(
        value < context.parent[FIELD_NAME.people_supported_count] * 15 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[0].id
        )
      )
    })
    .test('more-than-50', AT_LEAST_50_IN_KIND, (value, context) => {
      return !(
        value < context.parent[FIELD_NAME.people_supported_count] * 50 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[1].id
        )
      )
    }),
  [FIELD_NAME.level]: Yup.object()
    .required(REQUIRED)
    .test('level-custom', CUSTOM_LEVEL, (value, context) => {
      return !(
        ((value as Option)?.value === LEVEL_ITEMS.limited &&
          context.parent[FIELD_NAME.type_of_initiative].length > 1) ||
        ((value as Option)?.value === LEVEL_ITEMS.substantive &&
          context.parent[FIELD_NAME.type_of_initiative].length === 1)
      )
    }),
  [FIELD_NAME.duration]: Yup.object().required(REQUIRED),
  [FIELD_NAME.coworkers_count]: Yup.number()
    .integer(INTEGER)
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('dependent_type_coworker', COWORKER_ENGAGEMENT_ERROR, (value, context) => {
      return !(
        value <= 0 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.value === TYPE_OF_INITIATIVE_OPTIONS[5].value
        )
      )
    }),
  [FIELD_NAME.coworker_hours]: Yup.number().typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.customers_count]: Yup.number()
    .integer(INTEGER)
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('dependent_type_customer', CUSTOMER_ENGAGEMENT_ERROR, (value, context) => {
      return !(
        value <= 0 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.value === TYPE_OF_INITIATIVE_OPTIONS[6].value
        )
      )
    })
})

export const DraftReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.report_period]: Yup.string().required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS),
  [FIELD_NAME.people_supported_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.spent]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworkers_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworker_hours]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.customers_count]: Yup.number().nullable().typeError(NUMBER)
})
